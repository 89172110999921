import type { KeyboardEvent as ReactKeyboardEvent } from "react"

export function keyComboFrom(event: KeyboardEvent | ReactKeyboardEvent) {
  const modKeys = ["ctrl", "shift", "alt", "meta"]
  const keyCombo = new Set()

  modKeys.forEach((modKey) => {
    if (event[modKey + "Key"]) keyCombo.add(modKey)
  })

  if (event.key) {
    keyCombo.add(event.key.toLowerCase())
  }

  return Array.from(keyCombo).join("+")
}
